import Vue from 'vue'
import App from './App.vue'
import router from './router'


import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

import '../public/theme/theme.css'
import Element from "element-ui"; 
import './styles/element-variables.scss'

import "@/styles/index.scss"; // global css

import i18n from "./lang"; // Internationalization
import Cookies from "js-cookie";

import './permission.js'

import axios from 'axios'

import store from '../src/store'

import Rsa from "@/utils/rsa.js"


Vue.prototype.$axios = axios

Vue.prototype.$store = store

Vue.prototype.Rsa = Rsa

Vue.config.productionTip = false

Vue.use(VideoPlayer)


Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  render: h => h(App),
  router,
  i18n,
}).$mount('#app')
