export default {
  route: {
    dashboard: 'home page',
    page401: '401',
    page404: '404'
  },
  navbar: {
    logOut: 'log out',
    changePassword:'change password',
    dashboard: 'dashboard',
    train:'training',
    task:'task',
    news:'message',
    usernames:'username',
    mobile:'phone number',
    mail:'email address',
    oldPassword:'old password',
    newPassword:'new password',
    confirmPassword:'confirm new password',
  },
  login: {
    title: 'system login',
    logIn: 'login',
    username: 'please enter your email',
    password: 'please enter your password',
    verificationCode:'please enter the verification code',
    reName:'remember username',
    verificationCodeError:"wrong verification code",
    verificationMobileError:"incorrect phone number format",
    formError:"please enter complete form",
    logIn:'logIn'
  },
  table: {
    cancel: 'cancel',
    confirm: 'confirm'
  },
  common:{
    pleaseSelect:'please select',
    pleaseInput:'please enter',
    pleaseInputText:'please enter the content.'
  },
  home:{
    trainingProgress:'training progress',
    complete:"you're done",
    train:'training sessions',
    total:'In total',
    term:'items',
    continueLearning:'continue learning',
    message:'unread messages',
    more:'more',
    unread:'unread',
    myTraining:'my training',
    trainingName:'name of the training',
    status:'status',
    deadline:'deadline',
    startTrain:'start the training',
    myTask:'my task',
    taskName:'task name',
    type:'type',
    startTime:'start date',
    endTime:'end date.',
    startTask:'start the task.', 
  },
  train:{
    allTrain:'all training',
    all:'all',
    activated:'activated',
    closed:'closed',
    search:'please enter the search query',
    courseIntroduction:'course details',
    currentProgress:'current progress',
    catalogue:'catalogue',
    next:'next session',
    pre1:'previous one',
    next1:'next one',
    describe:'chapter details description',
    pleaseFinishPre:'please complete the previous chapters first',
    check:'check',
    downloadCertificate:'download certificate',
    startTraining:'start training.',
    Congratulations: 'Congratulations to you!',
    continue:'continue',
    rePlay:'replay',
    redo:'redo',
    retest:'retake the quiz',
    Retrospective:'review the training',
    training: 'complete the training.',
    accomplish:'You have completed the {title} training for {durationTimeDesc}.',
    questionTotal:'a total of {totalNum} questions.',
    mustAnswerAllQuestions:'please fill in all the questions',
    explain:'You have submitted a total of {totalNum} questions, and answered {rightNum} correctly. You need to answer at least {minRightNum} questions correctly in order to proceed with the training.',
    explainAll:'You have submitted a total of {totalNum} questions and answered {rightNum} correctly.',
    explainTo: 'You have submitted a total of {totalNum} questions and answered {rightNum} correctly. You have the option to redo the questions or continue.'
  },
  task:{
    allTask:'all tasks',
    taskName:'task Name',
    status:'status',
    type:'type',
    startTime:'start time',
    endTime:'end time',
    operation:'operation',
    completeTask:'complete task',
    see:'view',
    enterTaskName:'please provide the task name',
    searchType:'please select the task typ',
    searchStatus:'please enter the status',
    search:'search',
    questionnaire:'questionnaire',
    knowledge:'knowledge competition.',
    test:'exam',
    choose:'please select.',
    submit:'submit',
    close: 'closed',
    resubmit:'resubmit.',
    unStart:'not started',
  },
  news:{
    allNews:'all messages',
    all:'all',
    unread:'unread',
    read:'read',
    sender:'sender',
    messageSubject:'message subject',
    sendingTime:'sending time',
    remark:'aark all as read',
    delete:'delete',
    return:'return list.' 
  }
}
